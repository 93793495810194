<template>
<div>
<section class="page-top">
    <transition :name="exclusiontext">
        <h4>Scroll Down</h4>
    </transition>
</section>
<section id="about-section">

    <div id="about-header">
        <div class="inf-scroll">
            <div>
                <h1><span>My Name is</span> Kyler Herrera</h1>
                <h1><span>My Name is</span> Kyler Herrera</h1>
            </div>
        </div>
        
    </div>

    <div id="about-main">
        <div class="about-tagline">
            <h3>And it's nice to meet you too</h3>
        </div>

        <div class="about-content">
    
            <div class="content-row-2">
                <div>
                    <div>
                        <div class="image-flourish">
                            <img class="logo-flourish outer" aria-hidden="true" src="../assets/images/about/logo_outline.svg">
                            <img class="logo-flourish inner" aria-hidden="true" src="../assets/images/about/logo_monocolor.svg">
                        </div>
                        <img class="medium-img forward focus-highlight" alt="portrait of kyler herrera" src="../assets/images/about/kh_01.webp">
                    </div>
                </div>
                <div>
                    <h2>I'm a <span class="highlight">designer</span> based in <span class="highlight">San Antonio, Texas</span></h2>
                    <p class="copy">I'm a problem solver, first and foremost. My areas of expertise are many and I only get better every day. My skills range from <span class="highlight">brand identity</span> to 
                    <span class="highlight">web, print, marketing</span> and <span class="highlight">beyond</span>. I believe in pushing the envelope with <span class="highlight">creative</span> approaches to my 
                    projects, using <span class="highlight">original photography, artwork</span> and even <span class="highlight">3D modelling</span> to make something fresh and exciting. I never back down from 
                    a challenge - if I don't have a solution now, then I will find one.</p>
                </div>
            </div>

            <div class="skills-section">
                <h4>Areas of Expertise</h4>
            </div>

            <div class="hide-mobile about-skills">

                <div class="skills-list">
                    <button class="skills-button" data-skill-tab="1">Web Design</button>
                    <button class="skills-button" data-skill-tab="2">Graphic Design</button>
                    <button class="skills-button" data-skill-tab="3">Photography</button>
                    <button class="skills-button" data-skill-tab="4">Marketing</button>
                </div>

                <div class="skills-content" data-skill-content="1">
                    <p class="copy">Executing full projects including prototyping with <span class="highlight">Adobe Xd</span>. Strong knowledge of <span class="highlight">HTML, CSS</span> and <span class="highlight">
                        Javascript</span>. Building websites in <span class="highlight">Wordpress</span> using <span class="highlight">Elementor</span> and custom child themes. Experience with 
                        <span class="highlight">THREE JS, Lottie, NPM</span> and <span class="highlight">Vue 3</span>.</p>
                    <lottie class="skills-icon"
                        :options="lottieOptions.webDesign" 
                        v-on:animCreated="handleAnimation($event, 'webDesign')"/>
                </div>

                <div class="skills-content" data-skill-content="2">
                    <p class="copy">Working directly with my clients to craft <span class="highlight">professional brands</span>. Designing for a number of different industries. Creating <span class="highlight">full brand 
                        identities</span> including <span class="highlight">logo, business cards, stationary, packaging,</span> and more. Creating unique and striking 
                        <span class="highlight">motion graphics</span>.</p>
                    <lottie class="skills-icon"
                        :options="lottieOptions.graphicDesign"
                        v-on:animCreated="handleAnimation($event, 'graphicDesign')"/>
                </div>

                <div class="skills-content" data-skill-content="3">
                    <p class="copy">Experience in a <span class="highlight">fast-paced studio setting</span> working with dozens of clients a day. Understanding of <span class="highlight">lighting, composition,</span> and 
                    more. Integrating original photography in design for personal or business uses and preparing for <span class="highlight">print and digital</span>.</p>
                    <lottie class="skills-icon"
                        :options="lottieOptions.photography"
                        v-on:animCreated="handleAnimation($event, 'photography')"/>
                </div>

                <div class="skills-content" data-skill-content="4">
                    <p class="copy">Promoting client brands digitally via website <span class="highlight">search engine optimization, social media campaigns,</span> and <span class="highlight">digital advertising</span>. 
                    Setting up <span class="highlight">print media</span> and working with printers to acquire materials. Composing animated <span class="highlight">video advertisements</span>.</p>
                    <lottie class="skills-icon"
                        :options="lottieOptions.marketing"
                        v-on:animCreated="handleAnimation($event, 'marketing')"/>
                </div>

            </div>





            <div class="content-row-4 show-mobile">
                <div>
                    <h4 class="skill-title">Web Design</h4>
                    <p class="copy">Executing full projects including prototyping with <span class="highlight">Adobe Xd</span>. Strong knowledge of <span class="highlight">HTML, CSS</span> and <span class="highlight">
                        Javascript</span>. Building websites in <span class="highlight">Wordpress</span> using <span class="highlight">Elementor</span> and custom child themes. Experience with 
                        <span class="highlight">THREE JS, Lottie, NPM</span> and <span class="highlight">Vue 3</span>.</p>
                </div>
                <div>
                    <h4 class="skill-title">Graphic Design</h4>
                    <p class="copy">Working directly with my clients to craft <span class="highlight">professional brands</span>. Designing for a number of different industries. Creating <span class="highlight">full brand 
                        identities</span> including <span class="highlight">logo, business cards, stationary, packaging,</span> and more. Creating unique and striking 
                        <span class="highlight">motion graphics</span>.</p>
                </div>
                <div>
                    <h4 class="skill-title">Photography</h4>
                    <p class="copy">Experience in a <span class="highlight">fast-paced studio setting</span> working with dozens of clients a day. Understanding of <span class="highlight">lighting, composition,</span> and 
                    more. Integrating original photography in design for personal or business uses and preparing for <span class="highlight">print and digital</span>.</p>
                </div>
                <div>
                    <h4 class="skill-title">Marketing</h4>
                    <p class="copy">Promoting client brands digitally via website <span class="highlight">search engine optimization, social media campaigns,</span> and <span class="highlight">digital advertising</span>. 
                    Setting up <span class="highlight">print media</span> and working with printers to acquire materials. Composing animated <span class="highlight">video advertisements</span>.</p>
                </div>
            </div>

        </div>
    </div>
</section>
<footer-info class="footer-back" />
</div>
</template>

<script>
import FooterInfo from "../components/FooterInfo.vue"
import Lottie from 'vue-lottie';
import webDesignIcon from '../assets/json/webdesign_v1.json';
import graphicDesignIcon from '../assets/json/graphicdesign_v1.json';
import photographyIcon from '../assets/json/photography_v1.json';
import marketingIcon from '../assets/json/marketing_v1.json';


export default {
    name: "About",
    components: {
        FooterInfo,
        Lottie,
    },
    data() {
        return {
            skillanim: {
                webDesign: null,
                graphicDesign: null,
                photography: null,
                marketing: null
            },
            lottieOptions: {
                webDesign: {animationData: webDesignIcon, loop:true, autoplay: true, renderer: 'svg'},
                graphicDesign: {animationData: graphicDesignIcon, loop:true, autoplay: true, renderer: 'svg'},
                photography: {animationData: photographyIcon, loop:true, autoplay: true, renderer: 'svg'},
                marketing: {animationData: marketingIcon, loop:true, autoplay: true, renderer: 'svg'}
            }
        };
    },
    methods: {
        handleAnimation(anim, skill) {
            this.skillanim[skill] = anim;
        },
        skillButtons() {
            document.querySelectorAll(".skills-button").forEach(button => {
                button.addEventListener("click", () => {
                    const list = button.parentElement;
                    const skills = list.parentElement;
                    const tabNumber = button.dataset.skillTab;
                    const targetTab = skills.querySelector(`.skills-content[data-skill-content="${tabNumber}"]`);

                    list.querySelectorAll(".skills-button").forEach(button => {
                        button.classList.remove("active-skill");
                    });

                    skills.querySelectorAll(".skills-content").forEach(tab => {
                        tab.classList.remove("active-content");
                    });

                    button.classList.add("active-skill");
                    targetTab.classList.add("active-content");
                });
            });

            document.querySelectorAll(".about-skills").forEach(itemContainer => {
                itemContainer.querySelector(".skills-list .skills-button").click();
            })
        }
    },
    mounted() {
        this.skillButtons();
    },
}
</script>

<style lang="scss">


#about-section {
    box-sizing: border-box;
    min-height: 50vh;
    padding-top: 4em;
    padding-bottom: 5em;
    margin: 0 auto;
    background-color: $transparent_offblack;
    box-shadow: 0px -3px 10px black;
    overflow: hidden;

    h3 {
        font-size: 3vw;
        text-align: left;

        @include respond-to('medium') {
            font-size: 6vw;
            padding-left: 1em;
        }

        &::before {
            content: "//";
            font-size: 1.4em;
            font-weight: 700;
            -webkit-text-stroke: 1px $light;
                    color: transparent;
                    font-weight: 500;

                    @include respond-to('small') {
                        -webkit-text-stroke: 0px $light;
                        color: $medium;
                    }
        }
    }
}

#about-header {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 11vw;
    margin: auto;
    overflow: hidden;
    z-index: 1;

    .inf-scroll {

        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        white-space: nowrap;
        transition: all 1s ease;

        & > div {
            display: flex;
            animation: scrollText 20s infinite linear;
        }

            h1 {
                color: $light;
                font-size: 10vw;
                font-weight: 500;
                margin-right: 0.3em;

                span {
                    -webkit-text-stroke: 1px $light;
                    color: transparent;
                    font-weight: 500;

                    @include respond-to('small') {
                        -webkit-text-stroke: 0px $light;
                        color: $medium;
                    }
                }

            }
    }
}

.inf-scroll {
    white-space: nowrap;
    
}

#about-main {
    max-width: 100%;
    margin: 0 auto;

    @include respond-to('small') {
        max-width: 90%;
    }

    h2 {
        font-size: 5vw;
        text-align: left;
        margin-bottom: 2vh;

        @include respond-to('small') {
            font-size: 12vw;
        }

    }

}

.about-tagline {
    max-width: $contWidth;
    margin: 0 auto;
    padding-bottom: 5vh;
}

.about-content {
    max-width: $contWidth;
    margin: 0 auto;
}

.content-row-2 {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    & > div {
        width: 46%;
        padding: 2%;
    }

    @include respond-to('medium') {
        flex-flow: column wrap;

        & > div {
            width: 80%;
            padding: 5% 10%;
        }
    }

    @include respond-to('small') {
        flex-flow: column wrap;

        & > div {
            width: 100%;
            padding: 5% 0;
        }
    }
}

.content-row-4 {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 10vh 0;

    & > div {
        width: 21%;
        padding: 2%;
    }

    @include respond-to('medium') {
        flex-flow: row wrap;
        justify-content: center;

        & > div {
            width: 36%;
            padding: 5% 2%;
        }
    }

    @include respond-to('small') {
        flex-flow: column wrap;

        & > div {
            width: 100%;
            padding: 5% 0;
        }
    }
}

.medium-img {
    width: 80%;
    float: left;

    @include respond-to('small') {
        max-width: 80%;
        float: none;
        margin: 0 auto;
    }
}

.medium-img.forward {
    position: relative;
    z-index: 1;
}

.medium-img.focus-highlight {
    filter: contrast(1.5);
    transition: filter 0.25s ease-in-out;

    &:hover {
        filter: contrast(2);
    }
}

.image-flourish {
    position: relative;
    max-width: 30%;
    pointer-events: none;
}

.logo-flourish {
    position: absolute;
    top: 28vh;
    left: -75%;
    z-index: 2;

    @include respond-to('medium') {
        top: 28vh;
        left: -50%;
    }

    @include respond-to('small') {
        top: 20vh;
        left: -10%;
    }
}

.logo-flourish.inner {
    z-index: 0;
}

.skills-section {
    margin-top: 20vh;
    width: 100%;
    padding: 0 2%;
    text-align: left;
    margin-bottom: 3em;

    @include respond-to('small') {
        margin-bottom: 0;
        margin-top: 10vh;
    }

    h4 {
        font-size: 5em;
        letter-spacing: 0.05em;

        @include respond-to('small') {
            font-size: 2em;
        }

        &::before {
            content: "//";
        }
    }
}

.skill-title {
    font-size: 2.5em;
    text-align: left;
    padding: 3vh 0;

    @include respond-to('medium') {
        font-size: 4vw;
        padding: 5vh 0;
    }

    @include respond-to('small') {
        font-size: 10vw;
    }

    &::before {
            content: "//";
            font-size: 1.4em;
            font-weight: 700;
            -webkit-text-stroke: 1px $light;
                    color: transparent;
                    font-weight: 500;

                    @include respond-to('small') {
                        -webkit-text-stroke: 0px $light;
                        color: $medium;
                    }
        }
}

.highlight {
    color: $accent;
}

p .highlight {
    font-weight: 500;
}

.about-skills {
    width: 100%;
    display: flex;
    padding: 2%;
    margin-bottom: 15vh;

    .skills-list {
        width: 25%;
        flex-shrink: 0;

        @include respond-to('medium') {
            width: 45%;
        }

        button {
            font-family: ballinger-condensed, Helvetica, Arial, sans-serif;
            background-color: transparent;
            border: none;
            color: $medium;
            text-transform: uppercase;
            cursor: pointer;
            width: 100%;
            text-align: left;
            font-size: 2.5em;
            transition: all 0.25s;

            &:hover {
                color: $light;
                transition: all 0.25s;
            }

            &::before {
            content: "{ ";
            position: relative;
            top: 5px;
            font-size: 1.4em;
            font-weight: 700;
            -webkit-text-stroke: 1px $light;
            color: transparent;
            font-weight: 500;
            visibility: hidden;

                @include respond-to('small') {
                    -webkit-text-stroke: 0px $light;
                    color: $medium;
                }
            }

            &::after {
            content: " }";
            position: relative;
            top: 5px;
            font-size: 1.4em;
            font-weight: 700;
            -webkit-text-stroke: 1px $light;
            color: transparent;
            font-weight: 500;
            visibility: hidden;

                @include respond-to('small') {
                    -webkit-text-stroke: 0px $light;
                    color: $medium;
                }
            }

            &.active-skill {
                color: $light;

                &::before {
                    visibility: visible;
                }

                &::after {
                    visibility: visible;
                }
            }
        }
    }

    .skills-content {
        width: 71%;
        display: none;

        @include respond-to('medium') {
            width: 50%;
        }

        p {
            max-width: 50%;
            margin-top: 3em;

            @include respond-to('medium') {
                max-width: 100%;
                width: 100%;
                margin-top: 0;
            }
        }

        .skills-icon {
            width: 35% !important;
            margin-left: 0 !important;
            margin-top: -2em !important;

            @include respond-to('medium') {
                width: 60% !important;
                margin: 0 auto !important;
            }
        }

        &.active-content {
            display: flex;

            @include respond-to('medium') {
                flex-direction: column-reverse;
            }
        }
    }
}

.hide-mobile {
        display: flex;

        @include respond-to('small') {
            display: none !important;
        }
    }

    .show-mobile {
        display: none;

        @include respond-to('small') {
            display: block;
        }
    }

</style>